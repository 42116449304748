import React from 'react'
import { AuthenticationLayout as Layout } from 'presentation/components/AuthenticationLayout'
import { useTranslation } from 'react-i18next'

export const PasswordResetRequested = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <p className="text-xl">{t('パスワードの再設定')}</p>
      <div className="w-full px-6 pt-4 pb-8">
        <p>{t('パスワードの再設定を要求しました。')}</p>
        <p>{t('入力したメールアドレスをご確認ください。')}</p>
      </div>
    </Layout>
  )
}
